// To register class
import "src/core/apis/customErrors"
import "styles/global.css"

import { withBlitz } from "src/blitz-client"
import { useQueryErrorResetBoundary } from "@blitzjs/rpc"
import { AppProps, ErrorBoundary, ErrorFallbackProps } from "@blitzjs/next"
import App, { AppContext } from "next/app"
import { GoogleAnalytics, usePageViews } from "nextjs-google-analytics"
import { NextIntlProvider } from "next-intl"
import { AuthenticationError, AuthorizationError } from "blitz"
import LoginForm from "src/auth/components/LoginForm"
import { SnackbarProvider } from "notistack"
import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en.json"

import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import { CacheProvider, EmotionCache } from "@emotion/react"
import theme from "src/core/styles/theme"
import createEmotionCache from "src/core/utils/createEmotionCache"
import { GlobalStyles } from "@mui/material"
import ErrorPage from "src/core/components/ErrorPage"

interface MyAppProps extends AppProps<{ messages: any }> {
  emotionCache?: EmotionCache
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

TimeAgo.setDefaultLocale(en.locale)
TimeAgo.addLocale(en)

const MyApp = ({ Component, pageProps, emotionCache = clientSideEmotionCache }: MyAppProps) => {
  usePageViews()
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <NextIntlProvider messages={pageProps.messages} locale="en">
          <SnackbarProvider maxSnack={3}>
            <CssBaseline />
            <GlobalStyles
              styles={{
                html: {
                  scrollPaddingTop: "80px" /* height of sticky header */,
                },
              }}
            />
            <ErrorBoundary
              FallbackComponent={RootErrorFallback}
              onReset={useQueryErrorResetBoundary().reset}
            >
              <GoogleAnalytics strategy="lazyOnload" />
              {getLayout(<Component {...(pageProps as any)} />)}
            </ErrorBoundary>
          </SnackbarProvider>
        </NextIntlProvider>
      </ThemeProvider>
    </CacheProvider>
  )
}

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  if (error instanceof AuthenticationError) {
    return <LoginForm onSuccess={resetErrorBoundary} />
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorPage
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    const { statusCode, message, name, ...rest } = error
    return <ErrorPage statusCode={statusCode || 400} title={message || name} {...rest} />
  }
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  const locale = appContext.ctx.locale || "en"
  const FEATURE_FLAG = process.env.FEATURE_FLAG
  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      messages: (await import(`../messages/${locale}.json`)).default,
    },
    FEATURE_FLAG,
  }
}

export default withBlitz(MyApp)
