import { useMutation } from "@blitzjs/rpc"
import { AuthenticationError, PromiseReturnType } from "blitz"
import { Form, FORM_ERROR } from "src/core/components/Form"
import login from "src/auth/mutations/login"
import { Login } from "src/auth/validations"
import { Grid, Typography } from "@mui/material"
import TextInput from "src/core/components/input/TextInput"

type LoginFormProps = {
  onSuccess?: (user: PromiseReturnType<typeof login>) => void
}

export const LoginForm = (props: LoginFormProps) => {
  const [loginMutation] = useMutation(login)

  return (
    <Form
      submitText="Login"
      schema={Login}
      initialValues={{ email: "", password: "" }}
      onSubmit={async (values) => {
        try {
          const user = await loginMutation(values)
          props.onSuccess?.(user)
        } catch (error: any) {
          if (error instanceof AuthenticationError) {
            return { [FORM_ERROR]: "Email หรือ รหัสผ่านผิดพลาด" }
          } else {
            return {
              [FORM_ERROR]:
                "Sorry, we had an unexpected error. Please try again. - " + error.toString(),
            }
          }
        }
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }} fontWeight={600}>
            Email (@kmitl.ac.th)
          </Typography>
          <TextInput
            label="Email *"
            name="email"
            placeholder="Ex. course.syllabus@kmitl.ac.th"
            type="email"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }} fontWeight={600}>
            Enter your password
          </Typography>
          <TextInput
            label="Password *"
            name="password"
            placeholder="********"
            type="password"
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
    </Form>
  )
}

export default LoginForm
