import { responsiveFontSizes } from "@mui/material"
import { red } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"

const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: '"Bai Jamjuree", Roboto, sans-serif',
      body1: {
        fontWeight: 500,
      },
      body2: {
        fontWeight: 500,
      },
    },
    palette: {
      alternate: {
        main: "#f7faff",
        dark: "#edf1f7",
      },
      primary: {
        dark: "#a74b00",
        main: "#ef6c00",
        light: "#f28933",
      },
      secondary: {
        dark: "#121858",
        main: "#1a237e",
        light: "#474f97",
      },
      error: {
        main: red.A700,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          outlinedInherit: {
            border: 0,
            boxShadow: "rgb(0 0 0 / 7%) 0px 1px 2px, rgb(217 217 217) 0px 0px 0px 1px inset",
          },
          containedInherit: {
            backgroundColor: "#fff",
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            "&.Mui-error": {
              fontSize: "0.85rem",
              fontWeight: 500,
            },
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "hover",
          color: "text.primary",
        },
      },
    },
  })
)

export default theme
