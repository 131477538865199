import { Box, Container } from "@mui/material"
import ErrorComponent from "src/core/components/ErrorComponent"
import { ComponentProps, FC } from "react"

type Props = ComponentProps<typeof ErrorComponent>

const ErrorPage: FC<Props> = (props) => {
  return (
    <Box
      position={"relative"}
      minHeight={"calc(100vh)"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height={1}
      marginTop={-12}
      paddingTop={12}
    >
      <Container>
        <ErrorComponent {...props} />
      </Container>
    </Box>
  )
}

export default ErrorPage
