// https://blitzjs.com/docs/error-handling#custom-errors

import { RouteUrlObject } from "blitz"
import SuperJson from "superjson"

declare module globalThis {
  let _BLITZ_CUSTOM_ERROR_CLASS_REGISTERED: boolean
}

const error_props = ["name", "message", "code", "statusCode", "meta", "url"]

export type ErrorMeta = {
  richText?: string
}

export type ErrorOption = {
  meta?: ErrorMeta
  url?: string | RouteUrlObject
}

export abstract class ApiError extends Error {
  abstract statusCode: number
  message: string
  meta?: Record<string, any>
  url?: string | object

  constructor(message?: string, option?: ErrorOption) {
    super(message)

    if (message) {
      this.message = message
    }

    this.meta = option?.meta
    this.url = option?.url
  }
}

export class MethodNotAllowedError extends ApiError {
  name: string = "MethodNotAllowedError"
  statusCode: number = 405
}

export class UnprocessableEntityError extends ApiError {
  name: string = "UnprocessableEntityError"
  statusCode: number = 422
}

export class BadRequestError extends ApiError {
  name: string = "BadRequestError"
  statusCode: number = 400
}

export class ForbiddenError extends ApiError {
  name: string = "ForbiddenError"
  statusCode: number = 403
  message: string = `The page you're tying to access has restricted access.`
}

if (!globalThis._BLITZ_CUSTOM_ERROR_CLASS_REGISTERED) {
  SuperJson.registerClass(MethodNotAllowedError, {
    identifier: "MethodNotAllowedError",
    allowProps: error_props,
  })

  SuperJson.registerClass(UnprocessableEntityError, {
    identifier: "UnprocessableEntityError",
    allowProps: error_props,
  })

  SuperJson.registerClass(BadRequestError, {
    identifier: "BadRequestError",
    allowProps: error_props,
  })

  globalThis._BLITZ_CUSTOM_ERROR_CLASS_REGISTERED = true
}
