import { TextField, TextFieldProps } from "@mui/material"
import { ComponentProps, FC } from "react"
import { Controller, useFormContext } from "react-hook-form"

export type TextInputProps = TextFieldProps & {
  name: string
  shouldUnregister?: ComponentProps<typeof Controller>["shouldUnregister"]
  rules?: ComponentProps<typeof Controller>["rules"]
}

const TextInput: FC<TextInputProps> = (props) => {
  const { defaultValue, shouldUnregister, rules, ...restProps } = props
  const { control } = useFormContext()
  return (
    <Controller
      control={control}
      defaultValue={props.defaultValue || ""}
      shouldUnregister={shouldUnregister}
      rules={rules}
      name={props.name}
      render={({ field, fieldState }) => (
        <TextField
          variant="outlined"
          error={!!fieldState.error}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...restProps}
          name={field.name}
          inputRef={field.ref}
          helperText={fieldState.error?.message || restProps.helperText}
        />
      )}
    />
  )
}

export default TextInput
