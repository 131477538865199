import { useRouter } from "next/router"
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import { ErrorOption } from "src/core/apis/customErrors"
import Link from "src/core/components/Link"
import { FC } from "react"

interface Props extends ErrorOption {
  statusCode: number
  title?: string
}

const BackButton = ({ url }: { url: ErrorOption["url"] }) => {
  const router = useRouter()

  if (!url || typeof url === "string") {
    return (
      <Button component={Link} variant="contained" color="primary" size="large" href={url || "/"}>
        Back
      </Button>
    )
  }

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={() => {
        router.replace(url)
      }}
    >
      Back
    </Button>
  )
}

const ErrorComponent: FC<Props> = ({ statusCode, title, meta, url }) => {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  })

  return (
    <Grid container>
      <Grid item container alignItems={"center"} justifyContent={"center"} xs={12} md={6}>
        <Box>
          <Typography
            variant="h1"
            component={"h1"}
            align={isMd ? "left" : "center"}
            sx={{ fontWeight: 700 }}
          >
            {statusCode}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            color="text.secondary"
            align={isMd ? "left" : "center"}
          >
            {title}
          </Typography>
          <Box marginTop={4} display={"flex"} justifyContent={{ xs: "center", md: "flex-start" }}>
            <BackButton url={url} />
            {/* <Button component={Link} variant="contained" color="primary" size="large" href={"/"}>
              Back
            </Button> */}
          </Box>
        </Box>
      </Grid>
      <Grid item container justifyContent={"center"} xs={12} md={6}>
        <Box height={1} width={1} maxWidth={500}>
          <Box
            component={"img"}
            src={"/drawkit-illustration6.svg"}
            width={1}
            height={1}
            sx={{
              filter: theme.palette.mode === "dark" ? "brightness(0.8)" : "none",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default ErrorComponent
